import * as React from 'react'

export default () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.5" cy="20" r="19.5" stroke="#CBD5E0">
      <title>This page was not helpful.</title>
    </circle>
    <path
      d="M23.4999 23L20.4 31C19.6839 31 18.9971 30.7155 18.4908 30.2092C18.0918 29.8102 17.4999 29.0161 17.4999 28.3L17.4999 25H12.606C12.3451 25.003 12.0867 24.9491 11.8486 24.8423C11.6105 24.7355 11.3986 24.5781 11.2274 24.3812C11.0561 24.1843 10.9298 23.9526 10.857 23.702C10.7843 23.4514 10.7669 23.188 10.806 22.93L11.0673 20.9262M23.4999 23L23.4999 13.0001M23.4999 23H26.6999C27.1773 23 27.6351 22.8104 27.9727 22.4728C28.3103 22.1352 28.4999 21.6774 28.4999 21.2V14.8001C28.4999 14.3227 28.3103 13.8649 27.9727 13.5273C27.6351 13.1898 27.1773 13.0001 26.6999 13.0001L23.4999 13.0001M23.4999 13.0001L13.848 13.0001C13.4139 12.9952 12.9927 13.1474 12.6619 13.4285C12.3311 13.7097 12.1131 14.1009 12.048 14.5301L11.6693 17M11.0673 20.9262L15.4999 20.9262M11.0673 20.9262L11.6693 17M11.6693 17L15.9999 17"
      stroke="#A0AEC0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
